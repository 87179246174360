import { computed, defineComponent } from '@vue/composition-api';
import FinanceTable from '@/Finance/components/FinanceTable.vue';
import i18n from '@/i18n/i18n-setup';
import { useBookingFinanceQuery, UserRoleState } from '@/generated-types/graphql.types';
import BackButton from '@/components/BackButton.vue';
import { BookingRoutes } from '@/Booking/booking.const';
import groupBy from 'lodash/groupBy';
import Access from '@/components/Access.vue';
import { currencyFilter } from '@/util/templateFilters';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        FinanceTable,
        BackButton,
        Access
    },
    setup(_, context) {
        const { root } = context;
        const { result, loading } = useBookingFinanceQuery(() => ({
            bookingId: +root.$route.params.bookingId
        }), { fetchPolicy: 'cache-and-network' });
        const finances = computed(() => {
            const history = groupBy(result?.value?.get_booking_invoices_history, invoice => invoice?.invoice_type);
            return {
                proofs: history?.PROOF_OF_PAYMENT?.map(item => ({
                    created: new Date(),
                    invoice_id: item?.invoice_id,
                    host: (item?.order?.vendor.first_name || '').concat(' ', item?.order?.vendor.last_name || ''),
                    invoiced: 
                    // Workaround: if total paid == service fee, it means it was a post payment order
                    // To get the post payment status we need the booking object which is not present here
                    item?.order?.service_fee_total === item?.order?.total_paid
                        ? item?.order?.service_fee_total
                        : item?.order?.order_total,
                    paid: item?.order?.total_paid
                })),
                invoices: history?.INVOICE?.map(item => ({
                    created: new Date(),
                    invoice_id: item?.invoice_id,
                    host: (item?.order?.vendor.first_name || '').concat(' ', item?.order?.vendor.last_name || ''),
                    invoiced: 
                    // Workaround: if total paid == service fee, it means it was a post payment order
                    // To get the post payment status we need the booking object which is not present here
                    item?.order?.service_fee_total === item?.order?.total_paid
                        ? item?.order?.service_fee_total
                        : item?.order?.order_total,
                    paid: item?.order?.total_paid
                })),
                refunds: history?.REFUND?.map(item => ({
                    created: new Date(),
                    invoice_id: item?.invoice_id,
                    refund: item?.order?.refunded_total,
                    status: item?.payout_status
                })),
                payouts: history?.PAYOUT?.map(item => ({
                    created: new Date(),
                    invoice_id: item?.invoice_id,
                    payout: item?.order?.order_total - item?.order?.service_fee_total,
                    status: item?.payout_status
                }))
            };
        });
        const invoiceColumns = computed(() => {
            return [
                {
                    name: 'created',
                    label: i18n.t('finance.history.table.columns.created'),
                    sortable: true,
                    customElement: 'date'
                },
                {
                    name: 'invoice_id',
                    label: i18n.t('finance.history.table.columns.invoice'),
                    sortable: true
                },
                {
                    name: 'host',
                    label: i18n.t('finance.history.table.columns.host'),
                    sortable: true
                },
                {
                    name: 'invoiced',
                    label: i18n.t('finance.history.table.columns.invoiced'),
                    sortable: true,
                    format: data => currencyFilter(data)
                },
                {
                    name: 'paid',
                    label: i18n.t('finance.history.table.columns.paid'),
                    sortable: true,
                    format: data => currencyFilter(data)
                },
                {
                    name: 'actions',
                    label: i18n.t('finance.history.table.columns.resend_invoice'),
                    sortable: false,
                    customElement: 'actions'
                }
            ];
        });
        const refundColumns = computed(() => {
            return [
                {
                    name: 'created',
                    label: i18n.t('finance.history.table.columns.created'),
                    sortable: true,
                    customElement: 'date'
                },
                {
                    name: 'invoice_id',
                    label: i18n.t('finance.history.table.columns.invoice'),
                    sortable: true
                },
                {
                    name: 'refund',
                    label: i18n.t('finance.history.table.columns.refund'),
                    sortable: true,
                    format: data => currencyFilter(data)
                },
                {
                    name: 'status',
                    label: i18n.t('finance.history.table.columns.status'),
                    sortable: true,
                    customElement: 'status'
                },
                {
                    name: 'actions',
                    label: i18n.t('finance.history.table.columns.resend_invoice'),
                    sortable: false,
                    customElement: 'actions'
                }
            ];
        });
        const payoutColumns = computed(() => {
            return [
                {
                    name: 'created',
                    label: i18n.t('finance.history.table.columns.created'),
                    sortable: true,
                    customElement: 'date'
                },
                {
                    name: 'invoice_id',
                    label: i18n.t('finance.history.table.columns.invoice'),
                    sortable: true
                },
                {
                    name: 'payout',
                    label: i18n.t('finance.history.table.columns.amount'),
                    sortable: true,
                    format: data => currencyFilter(data)
                },
                {
                    name: 'status',
                    label: i18n.t('finance.history.table.columns.status'),
                    sortable: true,
                    customElement: 'status'
                }
            ];
        });
        return {
            finances,
            loading,
            invoiceColumns,
            payoutColumns,
            refundColumns,
            BookingRoutes,
            UserRoleState,
            useGetLocalizedPath
        };
    }
});
